<template>
	<el-backtop :visibility-height="500" :bottom="80" :right="60"><div class="backtop"></div></el-backtop>
</template>

<script>
export default {
	name: 'BackTop'
}
</script>

<style lang="scss" scoped>
.backtop {
	width: 100%;
	height: 100%;
	background-image: url('https://0.ss.faisys.com/image/backToTop/secondStyle/05.png?v=201505251717');
	background-size: 100% 100%;
}
</style>
