export default {
	methods: {
		commonAnim() {
			if (!this.animList) return;
			let list = this.animList;
			list.map((data) => {
				this.$scrollReveal.reveal(data.$el, {
					reset: data.reset,
					mobile: true,
					afterReset: function(el) { //离开视图时重置，移除动画类名
						el.classList.remove('animated');
						el.classList.remove(data.animated);
					},
					beforeReveal: function(el) { // 到达视图时触发，增加动画类名，添加执行动画时常
						el.classList.add('animated');
						el.classList.add(data.animated);
						if (data.duration)
							el.style['animation-duration'] = data.duration + 's';
						el.style['-webkit-animation-duration'] = data.duration + 's';
					}
				});
			});
		}
	}
}
