import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import MyText from './components/MyText/MyText.vue';
import MyFoot from './components/MyFoot/MyFoot.vue';
import BackTop from './components/BackTop/BackTop.vue';
import 'animate.css'
import VueLazyload from 'vue-lazyload'
import scrollReveal from "scrollreveal";
import Mixin from './mixins/index.js'

Vue.prototype.$scrollReveal = scrollReveal();
Vue.mixin(Mixin)
Vue.use(VueLazyload)
Vue.config.productionTip = false
Vue.use(ElementUI);

Vue.component('MyText', MyText)
Vue.component('MyFoot', MyFoot)
Vue.component('BackTop', BackTop)

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
