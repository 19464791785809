<template>
    <div class="nav" ref="nav">
        <div class="nav_left">
            <span>酷娱传媒 MCN</span>
            <span style="color: #646464"></span>
        </div>
        <div class="nav_right">
            <div class="nav_right_item" :class="{ active: p.id == $store.state.activeId }" v-for="p in navList" :key="p.id" @click="toPage(p)">{{ p.text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyHead',
    data() {
        return {
            navList: [
                {
                    id: 1,
                    text: '首页',
                    url: '',
                },
                {
                    id: 2,
                    text: '关于我们',
                    url: 'about',
                },
                {
                    id: 3,
                    text: '公司艺人',
                    url: 'actor',
                },
                {
                    id: 4,
                    text: '新闻动态',
                    url: 'news',
                },
                {
                    id: 5,
                    text: '联系我们',
                    url: 'contact',
                },
            ],
        }
    },
    mounted() {
        if (this.$store.state.isPhone) {
            this.$refs.nav.style.width = '1440px'
        }
    },
    methods: {
        toPage(p) {
            console.log(p.url)
            this.$router.push(
                {
                    path: `/${p.url}`,
                },
                () => {},
            )
            this.$store.commit('setactiveId', {
                activeId: p.id,
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.nav {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 79%;
    z-index: 99;
    cursor: pointer;

    &_left {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        font-size: 28px;
        color: #fe7600;
    }

    &_right {
        flex: 1;
        display: flex;
        justify-content: space-between;

        &_item {
            font-size: 18px;
            color: #c3c3c3;

            &:hover {
                color: #fe7600;
            }
        }

        .active {
            color: #fe7600;
        }
    }
}
</style>
