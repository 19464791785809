import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		activeId: 1,
		isPhone: false
	},
	getters: {},
	mutations: {
		setactiveId(state, payload) {
			state.activeId = payload.activeId
		},
		setisPhone(state, payload) {
			state.isPhone = payload.isPhone
		},
	},
	actions: {},
	modules: {},
	plugins: [
		createPersistedState({
			storage: window.sessionStorage,
		}),
	],
})
