<template>
	<div class="text">
		<div class="text_text" :style="{ color: color, fontSize: fontSize + 'px' }">{{ text }}</div>
		<div class="text_line" :style="{ backgroundColor: color }"></div>
	</div>
</template>

<script>
export default {
	name: 'MyText',
	data() {
		return {}
	},
	props: {
		text: {
			type: String,
			default: '我是标题'
		},
		fontSize: {
			default: 30
		},
		color: {
			default: '#fe7600'
		}
	}
}
</script>

<style lang="scss" scoped>
.text {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	min-width: 200px;
	&_text {
		// font-size: 30px;
		// color: #fe7600;
	}
	&_line {
		margin-top: 20px;
		width: 50px;
		height: 2px;
		background-color: #fe7600;
	}
}
</style>
