<template>
    <div id="app" ref="app">
        <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
        <MyHead></MyHead>
        <transition name="fade" mode="out-in">
            <router-view />
        </transition>
    </div>
</template>

<script>
import MyHead from '@/components/MyHead/MyHead.vue'
export default {
    components: {
        MyHead,
    },
    data() {
        return {
            screenWidth: '',
        }
    },
    mounted() {
        this.screenWidth = document.body.clientWidth

        console.log(this.screenWidth)
        if (this.isMobile()) {
            console.log('手机端')

            this.$store.commit('setisPhone', {
                isPhone: true,
            })
            // console.log(this.$refs.app.style)
            this.$refs.app.style.transform = `scale(${this.screenWidth / 1440})`
            this.$refs.app.style.transformOrigin = 'left top'
            this.$refs.app.style.backgroundSize = '100% 100%'
            this.$refs.app.style.height = '100vh'
            // 		$('body').css({
            // transform: "scale(" + $(window).width() / ($('body').width()+ ")",
            // transformOrigin: "left top",
            // backgroundSize: "100%"
            // });
        } else {
            console.log('pc端')
            this.$store.commit('setisPhone', {
                isPhone: false,
            })
        }

        // window.onresize = (a) => {
        //     console.log(a)
        // }
    },
    methods: {
        isMobile() {
            let flag = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
            )
            return flag
        },
    },
}
</script>
<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    // transform: scale(0.26);
    // transform-origin: left top;
    // background-size: 100% 100%;
    // height: 100vh;
}

body {
    margin: 0 !important;
    background-color: #0d0d0d;
}
</style>
