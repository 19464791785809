<template>
	<div class="myFoot">
		<div class="myFoot_title">酷娱传媒MCN</div>
		<div class="myFoot_navs">
			<div class="myFoot_navs_left">
				<div class="myFoot_navs_left_item" v-for="p in navList" :key="p.id" @click="toPage(p)">{{ p.text }}</div>
			</div>
			<div class="myFoot_navs_left"></div>
		</div>
		<div class="myFoot_group">
			<div>联系电话：13928450212</div>
			<div>联系邮箱：admin@kuyumcn.com</div>
			<div>地址：深圳市龙华区民治 梅花山庄 韵梅园23栋整栋</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MyFoot',
	data() {
		return {
			navList: [
				{ id: 1, text: '首页', url: '' },
				{ id: 2, text: '关于我们', url: 'about' },
				{ id: 3, text: '公司艺人', url: 'actor' },
				{ id: 4, text: '新闻动态', url: 'news' },
				{ id: 5, text: '联系我们', url: 'contact' }
			]
		}
	},
	methods: {
		toPage(p) {
			console.log(p.url)
			this.$router.push({ path: `/${p.url}` }, () => {})
			this.$store.commit('setactiveId', { activeId: p.id })
		}
	}
}
</script>

<style lang="scss" scoped>
.myFoot {
	box-sizing: border-box;
	padding: 80px 0px;
	background-color: #161616;
	position: relative;
	&_title {
		width: 80%;
		margin: 0 auto;
		display: flex;
		justify-content: flex-start;
		align-items: baseline;
		font-size: 26px;
		color: #fe7600;
		margin-bottom: 100px;
	}
	&_navs {
		width: 80%;
		margin: 0 auto;
		display: flex;
		height: 50px;
		border-bottom: 1px solid #555;
		&_left {
			flex: 1;
			display: flex;
			justify-content: space-between;
			&_item {
				font-size: 14px;
				color: #555;
			}
		}
	}
	&_group {
		width: 80%;
		margin: 0 auto;
		margin-top: 50px;
		display: flex;
		justify-content: space-between;
		font-size: 14px;
		color: #555;
	}
}
</style>
