import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'index',
	component: () => import( /* webpackChunkName: "about" */ '../views/index/IndexVIew.vue'),
	meta: {
		title: '首页'
	}
},
{
	path: '/about',
	name: 'about',
	meta: {
		title: '关于我们'
	},
	// route level code-splitting
	// this generates a separate chunk (about.[hash].js) for this route
	// which is lazy-loaded when the route is visited.
	component: () => import( /* webpackChunkName: "about" */ '../views/about/AboutView.vue')
},
{
	path: '/actor',
	name: 'actor',
	component: () => import( /* webpackChunkName: "about" */ '../views/actor/ActorView.vue'),
	meta: {
		// 页面标题title
		title: '公司艺人'
	}
},
{
	path: '/news',
	name: 'news',
	component: () => import( /* webpackChunkName: "about" */ '../views/news/NewsView.vue'),

	meta: {
		// 页面标题title
		title: '新闻动态'
	}
},
{
	path: '/contact',
	name: 'contact',
	component: () => import( /* webpackChunkName: "about" */ '../views/contact/ContactView.vue'),
	meta: {
		// 页面标题title
		title: '联系我们'

	}
},
{
	path: '/detail',
	name: 'detail',
	component: () => import( /* webpackChunkName: "about" */ '../views/news/DetailView.vue')
},
]


const router = new VueRouter({
	mode: 'history',
	routes
})
router.beforeEach((to, from, next) => {

	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = '酷娱传媒'
	}

	window.scrollTo(0, 0);
	next()
})
export default router
